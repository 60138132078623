<template>
  <div class="window-notes">
    <div ref="notes" class="notes">
      <div v-if="!notes.isLoading">
        <b-list-group v-if="notes.records.length > 0" flush>
            <b-list-group-item v-for="(item, k) in notes.records" :key="k">
              <div style="position: absolute; right: 10px; top: 3px;">
                <b-button size="sm" variant="link" @click="openModal(item)"><BIconPencil font-scale="0.7"/></b-button>
              </div>
              <div class="small mb-2">on {{ getDatetime(item.createdAt) }}</div>
              <div class="note">{{ item.content }}</div>
            </b-list-group-item>
        </b-list-group>
        <div v-else class="small p-3">you don`t have any notes</div>
      </div>
      <b-spinner v-else small style="margin: 20px;"></b-spinner>
    </div>
    <div class="note-box">
      <b-form-textarea v-model="content" placeholder="write a note..." rows="1" max-rows="1" no-resize
        @keydown.enter.exact.prevent
        @keyup.enter.exact="saveNote"
      ></b-form-textarea>
    </div>
    <b-modal ref="modalUpdateNote" size="lg" id="modal-update-note" title="Edit note" @hide="onModalClose()" @ok="updateNote()" ok-only>
      <b-form-textarea v-if="note"
        id="note-input"
        v-model="note.content"
        placeholder="write a note..."
        rows="6"
        no-resize
      ></b-form-textarea>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">Update</b-button>
        <b-button v-if="note" size="sm" variant="danger" @click="removeNote()">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BIconPencil} from 'bootstrap-vue';
import moment from 'moment';
export default {
  props: ['activeCall'],
  components: {
    BIconPencil
  },
  data(){
    return {
      note: null,
      content: null,
      notes: {
        isLoading: false,
        records: [],
        total: 0,
        pagination: {
          currentPageNo: 1
        }
      }
    };
  },

  methods: {
    getDatetime(createdAt){
      return moment(createdAt).format('Do MMM YYYY, HH:mm')
    },

    scrollToBottom(){
      let div = this.$refs['notes'];
      div.scrollTop = div.scrollHeight;
    },

    saveNote(){
      this.$api.post(`call/${this.activeCall.client.callId}/note`,{
        data: {content: this.content}
      }).then(() => {
        this.content = null;
        this.getNotes();
      }).catch(() => {console.log('cannot create note')});
    },

    getNotes(pageNo=1){
      this.notes.isLoading = true;
      this.$api.get(`call/${this.activeCall.client.callId}/notes`,{
        params: {
          perPage: 5,
          page: pageNo,
        }
      }).then(res => {
        let {records, pagination, total} = res.data;
        this.notes.isLoading = false;
        this.notes.records = records;
        this.notes.pagination = pagination;
        this.notes.total = total;

        this.scrollToBottom()
      }).catch(() => {console.log('cannot get notes')});
    },

    openModal(note){
      this.note = note;
      this.$refs['modalUpdateNote'].show();
    },

    onModalClose(){
      this.note = null;
    },

    updateNote(){
      this.$api.put(`call/${this.activeCall.client.callId}/note/${this.note.id}`,{
        data: {content: this.note.content}
      }).then(() => {
        this.note = null;
        this.getNotes();
        this.$refs['modalUpdateNote'].hide();
      }).catch(() => {console.log('cannot update note')});
    },
    removeNote(){
      this.$api.delete(`call/${this.activeCall.client.callId}/note/${this.note.id}`).then(() => {
        this.note = null;
        this.getNotes();
        this.$refs['modalUpdateNote'].hide();
      }).catch(() => {console.log('cannot delete note')});
    }
  },

  mounted(){
    this.getNotes();
  }
};
</script>
